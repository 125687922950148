@import "theme/variables";


.landing-page-multikart {
/*=====================
    1.General CSS start
==========================*/
body {
    position: relative;
    font-size: 14px;
    font-family: 'Lora', serif;
    line-height: 1.5;
}
h1 {
    font-size: 56px;
    text-transform: uppercase;
    color: #fff;
    line-height: 2;
    font-weight: 800;
    letter-spacing: 0.04em;
    span {
        font-weight: 700;
        font-size: 56px;
    }
}
h2 {
    font-weight: 100;
    font-size: 36px;
    text-transform: capitalize;
    line-height: 1.5;
}
h3 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.5;
}
h4 {
    font-size: 24px;
    font-weight: 400;
}
h5 {
    font-size: 16px;
    font-weight: 400;
    color: #555555;
}
ul {
    color: #fff;
    padding-left: 0;
    margin-bottom: 0;
}
li {
    font-size: 20px;
    line-height: 1;
}
p {
    font-size: 16px;
    color: #777777;
}
a {
    text-decoration: none;
    color: black;
    &:hover {
        text-decoration: none;
        color: black;
    }
}
.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
    &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}
.btn {
    &:focus {
        box-shadow: none;
    }
}
section, .section-t-space {
    padding-top: 70px;
}
.section-b-space {
    padding-bottom: 70px;
}
.section-space {
    padding-bottom: 20px;
}
.section-lr {
    padding-left: 80px;
    padding-right: 80px;
}
.title-landing {
    font-size: 20px;
    color: #000000;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-weight: 700;
}
.btn {
    text-transform: uppercase;
    transition: .3s ease-in-out;
    font-weight:500;
    color: white;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 25px;
    width: 200px;
    display: flex;
    justify-content: center;
    &:hover {
        border: 1px solid $theme-deafult;
        color: white;
        background-color: $theme-deafult;
    }
}
.btn-primary {
    padding: 12px 40px;
    background-color: $theme-deafult;
    font-size: 16px;
    border: 1px solid $theme-deafult;
    color: white;
    margin: 0 auto;
    &:active,
    &:focus {
        background-color: transparent !important;
        border: 1px solid $theme-deafult !important;
        color: $theme-deafult !important;
        box-shadow: none !important;
    }
}
.red {
    background-color: #ff4a42;
}
.orange {
    background-color: #ffbe26;
}
.green {
    background-color: #11c52c;
}



/*=====================
    2.Header CSS start
==========================*/
header {
    box-shadow: 0px 0px 5px 0px rgba(153,153,153,0.62);
    background-color: white;
    z-index: 999;
    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        transition: all 0.5s ease;
        animation: smoothScroll 1s forwards;
    }
}
.navbar-light {
    .navbar-nav {
        .nav-link {
            color: black;
            &:hover {
                color: $theme-deafult;
                transition: all 0.5s ease;
            }
        }
        .nav-item {
            a {
                text-transform: capitalize;
                &.active {
                    color: $theme-deafult;
                    transition: all 0.5s ease;
                }
            }
        }
    }
    .navbar-toggler {
        &:focus {
            outline: none;
        }
    }
}
.top-header {
    display: flex;
    height: 100%;
    align-items: center;

    .logo {
        img {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }

    .nav-link {
        font-weight: 700;
    }
}
.navbar {
    padding: 0;
    li {
        &.nav-item {
            font-size: 16px;
            padding: 0 10px;
        }
    }
}
.purchase-btn {
    letter-spacing: 0.3em;
    color: white;
    background: $theme-deafult;
    width: 160px;
    height: 65px;
    font-weight: 500;
    border-radius: unset;
    font-size: 15px;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}



/*=====================
    3.body CSS start
==========================*/
.main-img {
    position: relative;
    background-image: url("/assets/images/landing-Page/home.jpg");
    height:93vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .main-contain {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 93vh;
        width: 100%;
        > div {
            background-color: rgba(248, 248, 248, 0.50);
            z-index: 9;
            background-blend-mode: overlay;
            padding: 0 0 22px 0;
        }
        h1 {
            color: $theme-deafult;
            font-weight: 600;

            span {
                color: black;
            }

            padding-bottom:0;
        }

        h3 {
            color: black;

            span {
                color: $theme-deafult;
                font-weight: 700;
            }

            padding-bottom: 25px;
        }

        h4 {
            color: black;

            span {
                color: $theme-deafult;
            }
        }
    }
}
.home-decor {
    .decor {
        position: absolute;
        img {
            box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2);
        }
        &.decor-1 {
            top: 11%;
            left: 8%;
            img {
                animation: movebounce 3.2s linear infinite;
            }

        }
        &.decor-2 {
            top: 13%;
            left: 22%;
            img {
                animation: movebounce 2s linear infinite;
            }
        }
        &.decor-3 {
            top: 29%;
            left: 2%;
            img {
                animation: movebounce 2.6s linear infinite;
            }
        }
        &.decor-4 {
            top: 44%;
            left: 23%;
            img {
                animation: movebounce 2.2s linear infinite;
            }
        }
        &.decor-5 {
            left: -1%;
            top: 58%;
            img {
                animation: movebounce 3.2s linear infinite;
                box-shadow:none;
            }
        }
        &.decor-6 {
            top: 72%;
            left: 25%;
            img {
                animation: movebounce 3.02s linear infinite;
            }
        }
        &.decor-7 {
            top: 9%;
            right: 0;
            img {
                animation: movebounce 2.3s linear infinite;
            }
        }
        &.decor-8 {
            top: 17%;
            right: 22%;
            img {
                animation: movebounce 2.9s linear infinite;
            }
        }
        &.decor-9 {
            top: 45%;
            right: 15%;
            img {
                animation: movebounce 2.0s linear infinite;
            }
        }
        &.decor-10 {
            top: 45%;
            right: -1%;
            img {
                animation: movebounce 3s linear infinite;
                box-shadow:none;
            }
        }
        &.decor-11 {
            top: 66%;
            right: 22%;
            img {
                animation: movebounce 2.6s linear infinite;
            }
        }
    }
}
.main-demo-section {
    .feature-image {
        img {
            border-radius: 25px;
            box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.04);
        }
    }
}
.font-style {
    font-family: 'Pacifico', cursive;
    color: #656565;
    font-weight: 400;
    font-size: 22px;
    position: relative;
    text-transform: capitalize;
}
.color-varient {
    display: flex;
    padding-inline-start: 0px;
    padding-top: 5px;
    margin-bottom: 0;

    li {
        list-style: none;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        margin-right: 5px;
        //border: 1px solid gray;
        cursor: pointer;
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0px);
    }
}
.feature {
    p {
        max-width: 40%;
        margin: 0 auto 20px;
    }
}
.feature_row {
    .feature-box {
        padding: 0 15px;
        p {
            margin-bottom: 0;
            line-height: 1.5;
        }
        .bottom {
            display: flex;
            margin-top: 10px;
            h5 {
                margin-bottom: 0;
                color: #000000;
                font-weight: 600;
                text-transform: capitalize;
                i {
                    margin-right: 10px;
                }
                +h5 {
                    margin-left: 15px;
                }
            }
        }
        .icon {
            margin-top: 10px;
            img {
                +img {
                    margin-left: 5px;
                }
            }
        }
        &:hover {
            .feature-image {
                transform: scale(1.01);
            }
        }
    }
    .feature-image {
        border-radius: 25px;
        box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.06);
        transition: all 0.5s ease;
        img {
            border-radius: 25px;
            box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.04);
        }
    }
    .feature-content {
        display: flex;
        padding-top: 25px;
        h6 {
            padding-left: 18px;
            font-weight: 600;
            text-transform: capitalize;
        }
    }
    > div{
        &:nth-child(-n + 4) {
            margin-bottom: 30px;
        }
    }
}
.admin-title {
    h4 {
        font-weight: 700;
        color: black;
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        text-transform: capitalize;
        letter-spacing: 0.2em;
    }
}
.demo-slider {
    position: relative;
    background: url("/assets/images/landing-page/f-bg.jpg");
    height: 50vh;
    background-repeat: no-repeat;
    background-size: contain;
    .demo-contain {
        position: absolute;
        justify-content: center;
        h2 {
            color: #f6f2f6;
            font-weight: bold;
            font-size: 175px;
            top: 30%;
            left: 5%;

        }
    }
    .demo {
        position: relative;
        display: flex;
        z-index: 9;
        width: 100%;
        justify-content: center;
        text-align: center;
        .right-part {
            display: flex;
            align-items: center;
            margin-top: 100px;
        }
        h2 {
            font-weight: 700;
            color: black;
            font-size: 60px;
        }

        h4 {
            font-weight: 700;
            color: black;
            font-size: 22px;
            letter-spacing: 0.2em;
        }

    }

}
.res-img {
    display: none;
}
.main-demo {
    position: relative;
    margin-top: -35vh;
    z-index: 9;
}
.sticker {
    margin-top: 100px;
    margin-left: 45px;
}
.email-img {
    box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
}
ul.tabs {
    list-style: none;
    text-align: center;
    padding: 0 5px;
    li {
        color: $black;
        display: inline-block;
        padding: 10px 15px;
        cursor: pointer;
        border-radius: 25px;
        margin: 0 6px;
        transition: all 0.5s ease;
        font-size: 18px;
        &.current {
            background: $theme-deafult;
            color: white;
            box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.21);
            transition: all 0.5s ease;
        }
    }
}
.tab-content {
    display: none;
    &[style="display: block;"]{
        .demo-box img {
            animation: zoomIn 300ms ease-in-out;
        }
    }
    &.current {
        display: inherit;
        .demo-box img {
            animation: zoomIn 300ms ease-in-out;
        }
    }
}
.demo-box{
    margin-top: 35px;
    position: relative;
    img{
        box-shadow: 3px 5px 25px 0 rgba(0,0,0,.1);
        transition: all 0.5s ease;
    }
    h3{
        line-height: 28px;
        font-size: 22px;
        font-weight: 500;
        margin-top: 16px;
        color: black;
        text-align: center;
        text-transform: capitalize;
    }
    h6{
        font-size: 14px;
        letter-spacing: 0.1em;
        color: #586082;
    }
    &:hover {
        img {
            transform: translateY(-3px);
            transition: all 0.5s ease;
        }
        .ribbon-1 {
            transform: translateY(-3px);
            transition: all 0.5s ease;
        }
    }
}
.badge {
    font-size: 11px;
    margin-left: 10px;
    vertical-align: 10px;
    line-height: 1.4;
    padding: 1px 6px;
    font-weight: 700;
    position: relative;
    padding-right: 15px;
    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 7px solid #ff4c3b;
        border-bottom: 5px solid transparent;
        left: -5px;
        top: 2.1px;
    }
    &:after {
        content: "";
        position: absolute;
        height: 7px;
        width: 7px;
        background-color: #ffffff;
        border-radius: 5px;
        top: 3.5px;
        right: 4px;
    }
    &.badge-danger {
        background-color: $theme-deafult;
    }
}
.special-feature{
    background-color: #f5f4f4;
    .feature_row {
        .feature-content {
            padding-top: 0;
            padding-bottom: 20px;
        }
    }
    .feature-sec {
        > div {
            margin-bottom: 40px;
            &:nth-last-child(-n + 3) {
                margin-bottom: 0;
            }
        }
    }
}
.email-section {
    background: url("/assets/images/landing-page/email-bg.png");
}
.cart{
    // background-image: url("/assets/images/Landing_Page/cart/cart.jpg");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.gallery{
    background: #f9f8f8;
    overflow: hidden;
}
.grey-bg {
    background: #f9f8f8;
}
.main-feature{
    .key-feature{
        >div {
            margin-top: 70px;
            &:nth-child(-n + 6) {
                margin-top: 0;
            }
        }
        .theme-collection{
            .image-contain{
                background-color: rgba(249, 249, 249, 0.70);
                padding: 50px;
                transition: all 0.5s ease;
                img {
                    filter: grayscale(100%);
                    transition: all 0.5s ease;
                }
                .set-image{
                    text-align: center;
                }
            }
            h5 {
                margin-top: 20px;
                text-align: center;
                color: #555;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
                padding-bottom: 0;
                margin-bottom: 0;
                line-height: 1;
                font-weight:700;
            }
            &:hover{
                .image-contain{
                    box-shadow: 0px 0px 26px 3px #e2e2e2;
                    transition: all 0.5s ease;
                }
                h5{
                    color: $theme-deafult;
                    transition: all 0.3s ease;
                }
                img {
                    filter: grayscale(0);
                    transition: all 0.5s ease;
                }
            }

        }
    }
    .theme-collection{
    }
}
.support-section {
    position: relative;
    .support-content {
        text-align: center;
        display: flex;
        h4{
            margin-top: 25px;
            font-size: 18px;
            text-transform: capitalize;
            margin-bottom: 0;
            font-weight: 400;
        }
        p{
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 16px;
            color: #777777;
        }
    }
    .support-text {
        position: absolute;
        left: 0;
        top: 0;
    }
    .support-image {
        margin-top: 35px;
    }
}
.footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    .rate-section {
        li {
            display: inline-block;
            padding: 0 3px;
            i {
                color: #ffd203;
                font-size: 36px;
            }
        }
    }
    h2 {
        letter-spacing: 0.1em;
        margin: 20px 0 30px 0;
        line-height: 1.2;
    }
}
.footer {
    background: url("/assets/images/landing-page/footer.jpg");
}
.effect-cls {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        background: url("/assets/images/game/top.png");
        top: -1px;
        width: 100%;
        height:85px;
        z-index: 1;
        background-repeat: no-repeat;
    }
    &:before {
        content: "";
        position: absolute;
        background: url("/assets/images/game/back.png");
        bottom: -12px;
        width: 100%;
        height: 110px;
        z-index: 1;
    }
    &.footer-effect {
        &:before {
            display: none;
        }
    }
}
.tap-top {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    display: none;
    color: #fff;
    text-align: center;
    background: $theme-deafult;
    border: none;
    font-size: 22px;
    padding: 7px;
    cursor: pointer;
    &.top-cls {
        bottom: 130px;
    }
}



/*=====================
    4.Responsive CSS start
==========================*/
@media (max-width: 1800px){
    .home-decor {
        .decor {
            img {
                width: 55%;
            }
        }
    }
}
@media(max-width: 1430px) {
    .feature {
        p {
            max-width: 70%;
        }
    }
    .feature_row {
        > div {
            margin-bottom: 30px;
            &:nth-last-child(-n + 2) {
                margin-bottom: 0;
            }
        }
    }
    .main-demo-section {
        .feature_row {
            > div {
                margin-bottom: 0;
            }
            .feature-box {
                padding: 0;
            }
        }
    }
    .feature_row {
        .feature-box {
            padding: 0;
            p {
                font-size: 14px;
            }
        }
    }
}
@media (max-width: 1199px) {
    section ,.section-t-space{
        padding-top: 60px;
    }
    .section-b-space {
        padding-bottom: 60px;
    }
    .main-feature {
        .key-feature {
            > div {
                margin-top: 30px;
                &:nth-child(-n + 6) {
                    margin-top: 30px;
                }
                &:nth-child(-n + 4) {
                    margin-top: 0;
                }
            }
        }
    }
    .main-demo {
        margin-top: -40vh;
    }
    .main-img {
        height: 70vh;
        .main-contain {
            height: 70vh;
        }
    }
    .main-demo-section {
        .feature_row {
            .feature-box {
                p {
                    font-size: 14px;
                }
                .bottom {
                    h5 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media(max-width: 991px){
    section ,.section-t-space{
        padding-top: 50px;
    }
    .section-b-space {
        padding-bottom: 50px;
    }
    .navbar-collapse {
        position: fixed;
        width: 100%;
        top: 55px;
        z-index: 99;
        left: 0px;
        background-color: white;
        text-align: center;
        padding-bottom: 10px;
    }
    .main-img {
        height: 65vh;
        .main-contain {
            height: 65vh;
            > div {
                background-color: transparent;
                padding: 0;
            }
        }
    }
    .section-lr {
        padding-left: 40px;
        padding-right: 40px;
    }
    .home-decor {
        display: none;
    }
    .top-header{
        .btn{
            width: 145px;
            height: 55px;
        }
    }
    .main-img{
        .main-contain{
            h1 {
                font-size: 45px;
                span {
                    font-size: 45px;
                }
            }
            h3{
                font-size: 25px;
            }
            h4{
                font-size: 23px;
            }
        }
    }
    .feature {
        p {
            max-width: unset;
            margin: 0 auto;
        }
    }
    .mr-auto, .mx-auto {
        margin-right: 15px !important;
    }
    ul.tabs{
        li {
            padding: 8px 12px;
        }
    }
    .demo-box{
        h3{
            font-size: 20px;
        }
    }
    .gif {
        width: 90%;
    }
    .key-feature{
        >div {
            margin-top: 70px;
            &:nth-child(-n + 6) {
                margin-top: 0;
            }
        }
    }
    .responsive-img {
        background: url("/assets/images/landing-Page/respnsive-img.jpg");
        background-blend-mode: overlay;
        background-color: rgba(241, 241, 241, 0.88);
    }
    .main-demo {
        margin-top: -43vh;
    }
    .demo-slider {
        .demo {
            .right-part {
                margin-top: 60px;

            }
        }
    }
    .special-feature {
        .feature-sec {
            > div {
                &:nth-last-child(-n + 3) {
                    margin-bottom: 40px;
                }
                &:nth-last-child(-n + 1) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .footer-section {
        h2 {
            font-size: 20px;
            margin: 5px 0 10px 0;
        }
        .rate-section {
            li {
                padding: 0;
                i {
                    font-size: 20px;
                }
            }
        }
    }
    .btn-primary {
        padding: 8px 10px;
    }
    .btn {
        width: 170px;
    }
}

@media(max-width: 767px){
    section ,.section-t-space{
        padding-top: 40px;
    }
    .section-b-space {
        padding-bottom: 40px;
    }
    .main-img {
        height: 60vh;
        .main-contain {
            height: 60vh;
            h1 {
                font-size: 36px;
                span {
                    font-size: 36px;
                }
            }
            h3 {
                padding-bottom: 14px;
            }
        }
    }
    .feature_row {
        > div {
            &:nth-last-child(-n + 2) {
                margin-bottom: 30px;
            }
            &:nth-last-child(-n + 1) {
                margin-bottom: 0;
            }
        }
    }
    .main-feature {
        .key-feature {
            > div {
                &:nth-child(-n + 4) {
                    margin-top: 30px;
                }
                &:nth-child(-n + 2) {
                    margin-top: 0;
                }
            }
        }
    }
    h1{
        font-size: 40px;
    }
    .main-img {
        .main-contain {
            h3 {
                font-size: 20px;
            }

            h4 {
                font-size: 20px;
            }
        }
    }
    .feature-image{
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    .feature-content {
        justify-content: center;
    }
    .para{
        text-align: center;
    }
    .main-demo {
        margin-top: -13vh;
    }
    .demo-slider {
        height: 21vh;
        background-size: cover;
        .demo {
            h2 {
                font-size: 35px;
            }

            h5 {
                font-size: 15px;
            }

            h6 {
                font-size: 12px;
            }
        }
    }
    ul.tabs {
        li {
            padding: 5px 9px;
            font-size: 16px;
        }
    }
    .demo-box {
        h3 {
            font-size: 18px;
        }
    }
    .gallery2{
        padding-top: 30px;
    }
    .key-feature {
        >div:nth-last-child(-n + 21) {
            margin-top: 35px;

        }
    }
    .support-section{
        .support-content{
            justify-content: center;
        }
    }
    .tap-top {
        right: 20px;
    }
    .email-section {
        .center-part {
            order: -1;
        }
        .left-part, .right-part {
            display: none;
        }
    }
    .full-img {
        display: none;
    }
    .res-img {
        display: block;
    }
    .purchase-btn {
        letter-spacing: 0.09em;
        font-size: 14px;
    }
    .top-header {
        .btn {
            width: auto;
            height: 45px;
        }
    }
    .btn {
        padding: 12px 10px;
    }
    .main-demo-section {
        .feature_row {
            > div {
                &:nth-child(-n + 2) {
                    margin-bottom: 30px;
                }
            }
            .feature-box {
                .bottom {
                    justify-content: center;
                }
                .icon {
                    text-align: center;
                }
            }
        }
    }
}

@media(max-width: 640px) and (min-width: 599px) {
    .main-demo {
        margin-top: 0vh;
    }
}

@media (max-width: 600px) {
    .main-demo {
        margin-top: -13vh;
    }
}

@media(max-width: 576px) {
    .section-b-space {
        padding-bottom: 30px;
    }
    section, .section-t-space {
        padding-top: 30px;
    }
    p {
        font-size: 14px;
    }
    .navbar-toggler {
        padding: 6px;
    }
    .navbar-toggler-icon {
        width: 20px;
        height: 20px;
    }
    .main-img {
        height: 58vh;
        .main-contain {
            height: 58vh;
        }
    }
    .sticker {
        display: none;
    }
    .main-img {
        .main-contain {
            img {
                width: 53%;
            }
        }
    }
    .section-lr {
        padding-left: 30px;
        padding-right: 30px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
    .demo-slider {
        height: 25vh;
        background-size: cover;
        background-blend-mode: overlay;
        background-color: rgba(252, 252, 252, 0.67);
        .demo {
            h2 {
                font-size: 25px;
            }

            h5 {
                font-size: 14px;
            }
            h4 {
                font-size: 18px;
            }
        }
    }
    .demo-box {
        margin-top: 30px;
        h3 {
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 10px;
        }
    }
    .theme-collection{
        h5 {
            font-size: 16px;
        }
    }
    .support-section {
        .support-content {
            justify-content: center;
            h4 {
                font-size: 17px;
            }
        }
    }
    .tap-top {
        width: 35px;
        height: 35px;
        padding: 2px;
        font-size: 19px;
    }
}

@media(max-width: 480px) {
    section,
    .section-t-space {
        padding: 35px 0;
    }
    .section-lr {
        padding-left: 0;
        padding-right: 0;
    }
    .main-img {
        .main-contain {
            h1 {
                font-size: 30px;
                padding-bottom: 0;
                span {
                    font-size: 30px;
                }
            }

            h3 {
                font-size: 16px;
                padding-bottom: 15px;
            }
            h4{
                font-size: 14px;
            }
        }
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 14px;
    }
    .main-img {
        .main-contain {
            h3 {
                font-size: 16px;
            }

            h4 {
                font-size: 16px;
            }
        }
    }
    .demo-slider {
        .demo {
            h2 {
                font-size: 20px;
            }

            h5 {
                font-size: 12px;
            }
        }
    }
    .demo-box {
        h3 {
            font-size: 14px;
        }
    }
    .theme-collection {
        h5 {
            font-size: 16px;
        }
    }
    .support-section {
        .support-content {
            justify-content: center;

            h4 {
                font-size: 16px;
            }
        }
    }
    .main-img {
        height: 55vh;
        .main-contain {
            height: 55vh;
        }
    }
}

@media(max-width: 360px) {
    section,
    .section-t-space {
        padding: 30px 0;
    }
    h1 {
        font-size: 25px;
    }
    .main-img {
        .main-contain {
            h1{
                font-size: 25px;
                padding-bottom: 10px;
                span {
                    font-size: 25px;
                }
            }
            h3 {
                font-size: 14px;
                padding-bottom: 10px;
            }

            h4 {
                font-size: 14px;
            }
        }
    }
    .demo-slider {
        .demo {
            h2 {
                font-size: 15px;
            }

            h5 {
                font-size: 10px;
            }
        }
    }
    .main-feature{
        .key-feature{
            .theme-collection {
                .image-contain {
                    padding: 25px;
                }
            }
        }
    }
    .support-section {
        .support-content {
            h4 {
                font-size: 14px;
            }
            p{
                font-size: 14px;
            }
        }
    }
    .main-img {
        height: 50vh;
        .main-contain {
            height: 50vh;
        }
    }
}

@media(max-width: 320px) {
    h1 {
        font-size: 20px;
    }
    .demo-slider {
        .demo {
            h2 {
                font-size: 15px;
            }

            h5 {
                font-size: 10px;
            }
        }
    }
    .rate-section {
        h2 {
            font-size: 15px;
        }

        ul {
            li {
                i {
                    font-size: 15px;
                }
            }
        }
    }
}
}

@import "../../../public/assets/scss/slick.scss";
@import "../../../public/assets/scss/slick-theme.scss";
@import "../../../public/assets/scss/animate.scss";
@import "../../../public/assets/scss/themify.scss";
@import "../../../public/assets/scss/color1.scss";
.search-bar{
    width: 80%;
    align-items: center;
    display: flex;
    input{
        width: 100%;
        height: 35px;
        border-radius: 55px;
        border: 1px solid #dddddd;
        padding-left: 20px;
    }
    input:focus{
        outline: none !important;        
    }
}
@media only screen and (max-width: 600px) {
    .search-bar{
    display: none;
    }
}

